import { DELETE_WATCHLIST_FAIL, DELETE_WATCHLIST_REQUEST, DELETE_WATCHLIST_SUCCESS, GET_WATCHLIST_FAIL, GET_WATCHLIST_REQUEST, GET_WATCHLIST_SUCCESS, INSERT_WATCHLIST_FAIL, INSERT_WATCHLIST_REQUEST, INSERT_WATCHLIST_SUCCESS } from "../constants/watchlistConstants"

export const getWatchListReducer = (state = { watchlistData: [] }, action) => {
  switch (action.type) {
    case GET_WATCHLIST_REQUEST:
      return { ...state, loading: true, watchlistData: [] }
    case GET_WATCHLIST_SUCCESS:
      return {
        loading: false,
        watchlistData: action.payload,
      }
    case GET_WATCHLIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const addWatchListItemReducer = (state = {}, action) => {
  switch (action.type) {
    case INSERT_WATCHLIST_REQUEST:
      return { loading: true }
    case INSERT_WATCHLIST_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      }
    case INSERT_WATCHLIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const deleteWatchListItemReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_WATCHLIST_REQUEST:
      return { loading: true }
    case DELETE_WATCHLIST_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      }
    case DELETE_WATCHLIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
