import React from "react"

const DisclaimerPage = () => {
  return (
    <>
      <br />
      <h1 className="text-center">Disclaimer </h1>
      <h2 className="text-center">No Investment Advice Provided</h2>
      <br />
      <p>Any opinions, chats, messages, news, research, analyses, prices, or other information contained on this Website are provided as general market information for educational and entertainment purposes only, and do not constitute investment advice. The Website should not be relied upon as a substitute for extensive independent market research before making your actual trading decisions. Opinions, market data, recommendations or any other content is subject to change at any time without notice. ProjectAlpha / Leanback AS. will not accept liability for any loss or damage, including without limitation any loss of profit, which may arise directly or indirectly from use of or reliance on such information.</p>
      <p>We do not recommend the use of technical analysis as a sole means of trading decisions. We do not recommend making hurried trading decisions. You should always understand that PAST PERFORMANCE IS NOT NECESSARILY INDICATIVE OF FUTURE RESULTS.</p>
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  )
}
export default DisclaimerPage
