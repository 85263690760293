import React, { useState, useEffect } from "react"
import { Col, Row, Form, Button } from "react-bootstrap"
import USMarket from "../components/USMarket"
import Meta from "../components/Meta"
import AmChartStockCompareComponent from "../components/AmChartStockCompareComponent"
import IndexHeader from "../components/IndexHeader"

const ComparePage = () => {
  const [ticker, setTicker] = useState("NVDA")
  const [symbol, setSymbol] = useState("NVDA")
  const [prevTicker, setPrevTicker] = useState("NVDA")
  const [refresh, setRefresh] = useState(true)

  useEffect(() => {
    if (ticker !== prevTicker) {
      setRefresh(false)
      setPrevTicker(ticker)
      console.log("Updating ticker to: " + ticker)
    }
  }, [ticker])

  useEffect(() => {
    if (refresh === false) {
      setRefresh(true)
      console.log("Updating Chart")
    }
  }, [refresh])

  const handleRefresh = (e) => {
    e.preventDefault()
    setRefresh(false)
    setTicker(symbol)

    setSymbol("")
  }

  return (
    <>
      <Meta title="ProjectAlpha | Market Overview" description="Market Overview Page" keywords="ProjectAlpha, Finding Alphas, TA, Technical Analysis, Market Scan, Watchlist, Compare, Patterns" />
      <IndexHeader onIndexSelect={setTicker} />
      <Form>
        {/* //inline className="d-flex justify-content-center" */}
        <Form.Group className="d-flex justify-content-left mt-2" controlId="formBasicTicker">
          {/* <Form.Control type="text" placeholder="Enter Ticker" value={symbol} onChange={(e) => setSymbol(e.target.value)} style={{ width: "150px" }} />
          < */}
          <Form.Control type="text" placeholder="Enter Ticker" value={symbol} onChange={(e) => setSymbol(e.target.value)} style={{ width: "150px" }} />
          <Button className="ml-1" variant="outline-dark" type="submit" onClick={(e) => handleRefresh(e)} style={{ borderRadius: "12px", borderWidth: "2px", height: "38px", fontWeight: "bold" }}>
            GO
          </Button>
        </Form.Group>
      </Form>
      {refresh && (
        <div className="d-flex justify-content-center">
          <div id="amchartstock" className="mt-3" style={{ width: "100%", height: "650px" }}>
            <AmChartStockCompareComponent company={prevTicker} symbol={prevTicker} volume="true" />
          </div>
        </div>
      )}

      <div>
        Check out more finance and IR widgets at{" "}
        <a href="https://investor-websolutions.com" target="blank">
          investor-websolutions.com.
        </a>
      </div>
    </>
  )
}

export default ComparePage
