import React from "react"
import SymbolComponent from "../components/SymbolComponent"

const HeatMapComponent = ({ data, onSelect }) => {
  return (
    <>
      {data.map((item) => (
        <SymbolComponent key={item.ticker} symbolData={item} onSelect={onSelect} />
      ))}
    </>
  )
}

export default HeatMapComponent
