import React from "react"
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap"

const SymbolComponent = ({ symbolData, onSelect }) => {
  const { ticker, last, percent, name } = symbolData
  var symbol = ticker
  var tooltipName = name
  var lastPrice = 0
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltipName}
    </Tooltip>
  )

  if (last) {
    if (last.toString().split(".")[1]) {
      if (last.toString().split(".")[1].length > 3) {
        lastPrice = last.toFixed(3)
      } else {
        if (last > 10000) {
          lastPrice = last.toFixed(0)
        } else if (last > 1000) {
          lastPrice = last.toFixed(1)
        } else if (last > 100) {
          lastPrice = last.toFixed(2)
        } else if (!last.toString().includes(".")) {
          lastPrice = last + ".0"
        } else {
          lastPrice = last
        }
      }
    } else {
      lastPrice = last
    }
  }

  if (symbol) {
    if (symbol.toString() === "LBS=F") {
      symbol = "Lumber"
      tooltipName = "Lumber Futures"
    } else if (symbol.toString() === "SI=F") {
      symbol = "Silver"
      tooltipName = "Silver Futures"
    } else if (symbol.toString() === "GC=F") {
      symbol = "Gold"
      tooltipName = "Gold Futures"
    } else if (symbol.toString() === "YM=F") {
      symbol = "Dow Fut."
      tooltipName = "Dow Jones Indus. Futures"
    } else if (symbol.toString() === "EURUSD=X") {
      symbol = "EUR/USD"
      tooltipName = "Euro / US Dollar"
    } else if (symbol.toString() === "ES=F") {
      symbol = "S&P Fut."
      tooltipName = "S&P 500 Futures"
    } else if (symbol.toString() === "NQ=F") {
      symbol = "Nasq Fut."
      tooltipName = "Nasdaq 100 Futures"
    } else if (symbol.toString() === "QQQ") {
      symbol = "QQQ"
      tooltipName = "Invesco QQQ Trust"
    } else if (symbol.toString() === "EURNOK=X") {
      symbol = "EUR/NOK"
      tooltipName = "EURO / NOK"
    } else if (symbol.toString() === "NOK=X") {
      symbol = "USD/NOK"
      tooltipName = "USD / NOK"
    } else if (symbol.toString() === "ETH-USD") {
      symbol = "Ethereum"
      tooltipName = "Ethereum USD"
    } else if (symbol.toString() === "^FVX") {
      symbol = "Yield 5yrs"
      tooltipName = "Treasury Yield 5 Years"
    } else if (symbol.toString() === "^TNX") {
      symbol = "Yield 10yrs"
      tooltipName = "Treasury Yield 10 Years"
    } else if (symbol.toString() === "BTC-USD") {
      symbol = "Bitcoin"
      tooltipName = "Bitcoin USD"
    } else if (symbol.toString() === "ALI=F") {
      symbol = "Aluminum"
      tooltipName = "Aluminum Futures"
    } else if (symbol.toString() === "DOGE-USD") {
      symbol = "DOGE"
      tooltipName = "Dogecoin USD"
    } else if (symbol.toString().includes(".OL")) {
      symbol = symbol.replace(".OL", "")
      tooltipName = name
    } else {
      symbol = ticker
      tooltipName = name
    }
  }

  const buttonHandler = (e) => {
    e.preventDefault()
    console.log("preventDefault()")
    onSelect(ticker)
  }

  return (
    <>
      <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
        <Button className={percent >= 0 ? "btnStylePos" : "btnStyleNeg"} style={{ boxShadow: "none" }} onClick={(e) => buttonHandler(e)}>
          <strong>{symbol}</strong>
          <label className="btnParagraph">
            {percent}% {lastPrice}
          </label>
        </Button>
      </OverlayTrigger>
    </>
  )
}

export default SymbolComponent
