import React from "react"

export const IndexComponent = ({ indexData, onSelect }) => {
  const { percent, last, name, ticker } = indexData
  //Button Handler
  const onSelectHandler = (ticker) => {
    console.log("IndexComponent: onSelectHandler: ", ticker)
    onSelect(ticker)
  }

  return (
    <>
      <button className="ml-1" style={btnStyle} onClick={() => onSelectHandler(ticker)}>
        <label style={{ fontWeight: "bold", fontSize: "15px" }}>{name}</label>
        <br />
        {"  "}
        <label>{last} </label>
        {"  "}
        {percent >= 0 ? <label style={lblStylePositive}>{percent}% </label> : <label style={lblStyleNegative}>{percent}% </label>}
      </button>
    </>
  )
}
const btnStyle = {
  background: "#222",
  color: "#fff",
  border: "none",
  padding: "5px 2px",
  lineHeight: "13px",
  cursor: "pointer",
  margin: "0px 0px",
  height: "54px",
}

const lblStyleNegative = {
  color: "#C00",
  border: "none",
  padding: "2px 4px",
  borderRadius: "20%",
  cursor: "pointer",
  margin: "0px 5px",
}

const lblStylePositive = {
  color: "#0C0",
  border: "none",
  padding: "2px 4px",
  borderRadius: "20%",
  cursor: "pointer",
  margin: "0px 5px",
}
