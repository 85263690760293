import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Message from "../components/Message"
import Loader from "../components/Loader"
import { getRSSfeed } from "../actions/rssActions"
import { Button, ButtonGroup, Col, Row } from "react-bootstrap"

const RSSComponent = () => {
  const dispatch = useDispatch()
  const [feed, setFeed] = useState([])
  const rssFeedResult = useSelector((state) => state.rssFeedResult)
  const { loadingFeed, errorFeed, RSSfeed } = rssFeedResult

  useEffect(() => {
    console.log("Fetcing RSS feed")
    dispatch(getRSSfeed("http://feeds.feedburner.com/zerohedge/feed"))
  }, [dispatch])

  useEffect(() => {
    if (RSSfeed) {
      if (Array.isArray(RSSfeed.items)) {
        console.log("Setting Feed")
        setFeed(Array.from(RSSfeed.items))
      }
    }
  }, [RSSfeed])

  const buttonHandler = (feedUri) => {
    console.log("buttonHandler: " + feedUri)
    dispatch(getRSSfeed(feedUri))
  }

  return (
    <>
      <Row className="" style={{ backgroundColor: "#222", border: "none", color: "#e8b50f", borderRadius: "1mm" }}>
        <Col>
          <ButtonGroup aria-label="Basic example">
            <Button style={{ backgroundColor: "#222", border: "none", color: "#e8b50f" }} onClick={() => buttonHandler("http://feeds.feedburner.com/zerohedge/feed")}>
              ZeroHedge
            </Button>
            <Button style={{ backgroundColor: "#222", border: "none", color: "#e8b50f" }} onClick={() => buttonHandler("https://feeds.finance.yahoo.com/rss/2.0/headline?s=AAPL&region=US&lang=en-US")}>
              Seeking-Alpha
            </Button>
            <Button style={{ backgroundColor: "#222", border: "none", color: "#e8b50f" }} onClick={() => buttonHandler("http://feeds.marketwatch.com/marketwatch/marketpulse")}>
              MarketWatch
            </Button>
            <Button style={{ backgroundColor: "#222", border: "none", color: "#e8b50f" }} onClick={() => buttonHandler("https://www.cnbc.com/id/20910258/device/rss/rss.html")}>
              CNBC
            </Button>
            <Button style={{ backgroundColor: "#222", border: "none", color: "#e8b50f" }} onClick={() => buttonHandler("https://www.investing.com/rss/news_25.rss")}>
              Investing.com
            </Button>
            <Button style={{ backgroundColor: "#222", border: "none", color: "#e8b50f" }} onClick={() => buttonHandler("https://services.dn.no/api/feed/rss/")}>
              DN
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      {loadingFeed && !RSSfeed ? (
        <Loader />
      ) : errorFeed ? (
        <Message variant="danger">{errorFeed}</Message>
      ) : (
        <>
          {feed.slice(0, 13).map((item) => (
            <a href={item.link} className="feedStyle" target="_blank" rel="noreferrer" key={item.pubDate}>
              <strong>
                {new Date(item.pubDate).toLocaleTimeString().replace(":00", "")}
                {" - "} {item.title} <br />
              </strong>
            </a>
          ))}
        </>
      )}
    </>
  )
}

export default RSSComponent
