import axios from "axios"
import { CALC_SHARPE_FAIL, CALC_SHARPE_REQUEST, CALC_SHARPE_SUCCESS, COMPARE_FAIL, COMPARE_REQUEST, COMPARE_SUCCESS, GET_REDDIT_FAIL, GET_REDDIT_REQUEST, GET_REDDIT_SUCCESS, SCAN_MARKET_FAIL, SCAN_MARKET_REQUEST, SCAN_MARKET_SUCCESS } from "../constants/algorithmConstants"

export const calcSharpeRatio = (symbols) => async (dispatch) => {
  try {
    dispatch({
      type: CALC_SHARPE_REQUEST,
    })

    const config = {
      headers: {
        "Content-Type": "application/json",
        symbols: symbols,
      },
    }
    var baseUrl = process.env.REACT_APP_BACKEND_URL
    const { data } = await axios.get(`${baseUrl}/api/algorithm/sharpe`, config)
    dispatch({
      type: CALC_SHARPE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CALC_SHARPE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const compareSymbols = (symbols) => async (dispatch) => {
  try {
    console.log("Frontend - compareSymbols: " + JSON.stringify(symbols))
    dispatch({
      type: COMPARE_REQUEST,
    })

    const config = {
      headers: {
        "Content-Type": "application/json",
        symbols: symbols,
      },
    }
    var baseUrl = process.env.REACT_APP_BACKEND_URL
    const { data } = await axios.get(`${baseUrl}/api/algorithm/compare`, config)
    dispatch({
      type: COMPARE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: COMPARE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getRedditPost = (forum) => async (dispatch, getState) => {
  try {
    console.log("Frontend - getRedditPost: " + JSON.stringify(forum))
    dispatch({
      type: GET_REDDIT_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        forum: forum,
      },
    }
    var baseUrl = process.env.REACT_APP_BACKEND_URL
    const { data } = await axios.get(`${baseUrl}/api/algorithm/reddit`, config)
    dispatch({
      type: GET_REDDIT_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_REDDIT_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const scanMarket = (scanlist) => async (dispatch, getState) => {
  try {
    console.log("Frontend - scanMarket ", scanlist)
    dispatch({
      type: SCAN_MARKET_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        scan: scanlist,
      },
    }
    var baseUrl = process.env.REACT_APP_BACKEND_URL
    const { data } = await axios.get(`${baseUrl}/api/algorithm/scan`, config)
    dispatch({
      type: SCAN_MARKET_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: SCAN_MARKET_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
