import React, { useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import Meta from "../components/Meta"

const AboutUsPage = () => {
  useEffect(() => {
    const script = document.createElement("script")
    const script2 = document.createElement("script")
    script.src = "https://leanback.no/widgets/amchartvol_index.js"
    script2.src = "https://leanback.no/widgets/quote4_index.js"
    script.async = true
    document.body.appendChild(script)
    script2.async = true
    document.body.appendChild(script2)
    return () => {}
  }, [])
  return (
    <>
      <Meta title="ProjectAlpha | About" description="About Page" keywords="ProjectAlpha, Finding Alpha, TA, Technical Analysis, Market Scan, Watchlist" />
      <Row className="mt-5">
        <Col md="12">
          <h3>About</h3>
          <br />
          <br />
          <a href="https://investor-websolutions.com/">Visit investor-websolutions.com for more IR and finance widgets</a>
          <div id="quote4" className="ml-4 mt-5" style={{ width: "870px", height: "251px" }}>
            <div className="leanback-quote4-widget" data-symbol="NVDA" />
          </div>
          <div style={{ width: "2000px", height: "10px" }}></div>
          <div id="amchartvol" className="ml-1 mt-5" style={{ width: "870px", height: "550px" }}>
            <div className="leanback-amchartVol-widget" data-symbol="NVDA" />
          </div>

          <br />
        </Col>
      </Row>
    </>
  )
}

export default AboutUsPage
