export const GET_RSS_REQUEST = "GET_RSS_REQUEST"
export const GET_RSS_SUCCESS = "GET_RSS_SUCCESS"
export const GET_RSS_FAIL = "GET_RSS_FAIL"

export const GET_NEWS_REQUEST = "GET_NEWS_REQUEST"
export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS"
export const GET_NEWS_FAIL = "GET_NEWS_FAIL"

export const GET_COMPANY_NEWS_REQUEST = "GET_COMPANY_NEWS_REQUEST"
export const GET_COMPANY_NEWS_SUCCESS = "GET_COMPANY_NEWS_SUCCESS"
export const GET_COMPANY_NEWS_FAIL = "GET_COMPANY_NEWS_FAIL"

export const GET_CONTENT_NEWS_REQUEST = "GET_CONTENT_NEWS_REQUEST"
export const GET_CONTENT_NEWS_SUCCESS = "GET_CONTENT_NEWS_SUCCESS"
export const GET_CONTENT_NEWS_FAIL = "GET_CONTENT_NEWS_FAIL"
