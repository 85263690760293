import React from "react"
import { Col, Row } from "react-bootstrap"
import RSSComponent from "../components/RSSComponent"
import NewsComponent from "../components/NewsComponent"
import Meta from "../components/Meta"
import IndexHeader from "../components/IndexHeader"

const NewsPage = () => {
  return (
    <>
      <Meta title="Stockview | News Overview" description="News Overview Page" keywords="ProjectAlpha, Finding Alphas, TA, Technical Analysis, Market Scan, Watchlist, Compare, Patterns" />
      <div style={{ pointerEvents: "none" }}>
        <IndexHeader className="mb-2" />
      </div>

      <Row className="mb-3 mt-2">
        <Col>
          <NewsComponent />
        </Col>
        <Col md="7">
          <RSSComponent />
        </Col>
      </Row>
    </>
  )
}

export default NewsPage
