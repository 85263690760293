export const GET_WATCHLIST_REQUEST = "GET_WATCHLIST_REQUEST"
export const GET_WATCHLIST_SUCCESS = "GET_WATCHLIST_SUCCESS"
export const GET_WATCHLIST_FAIL = "GET_WATCHLIST_FAIL"

export const INSERT_WATCHLIST_REQUEST = "INSERT_WATCHLIST_REQUEST"
export const INSERT_WATCHLIST_SUCCESS = "INSERT_WATCHLIST_SUCCESS"
export const INSERT_WATCHLIST_FAIL = "INSERT_WATCHLIST_FAIL"

export const DELETE_WATCHLIST_REQUEST = "DELETE_WATCHLIST_REQUEST"
export const DELETE_WATCHLIST_SUCCESS = "DELETE_WATCHLIST_SUCCESS"
export const DELETE_WATCHLIST_FAIL = "DELETE_WATCHLIST_FAIL"
