import React, { useEffect, useState } from "react"
import { Col, Row, Form, Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import IndexHeader from "../components/IndexHeader"
import Loader from "../components/Loader"
import Message from "../components/Message"
import { scanMarket } from "../actions/algorithmActions"
import MarketScanComponent from "../components/MarketScanComponent"
import Meta from "../components/Meta"

const MarketScanPage = () => {
  const dispatch = useDispatch()
  const [scanItems, setScanItems] = useState([])
  const [errorMsg, setErrorMsg] = useState("")
  const [pattern, setPattern] = useState(1)
  const scanResult = useSelector((state) => state.scanResult)
  const { loading, error, scanList } = scanResult
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      dispatch(scanMarket(false))
    } else {
      setErrorMsg("Please Login To View Market Scan View")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userInfo])

  useEffect(() => {
    if (!loading) {
      if (scanList) {
        if (scanList.length > 0) {
          setScanItems(scanList)
        }
      }
    }
  }, [loading, scanList])

  useEffect(() => {
    if (error) {
      if (error.toString() === "Not authorized, no token") {
        setErrorMsg("Please Login To View This Page")
      } else if (error.toString() === "Cannot read property 'token' of null") {
        setErrorMsg("Please Login To View This Page Or Reload This Page")
      } else if (error.toString() === "Request failed with status code 500") {
        setErrorMsg("Something Went Wrong... :(  Please Try To Reload Page!")
      } else {
        setErrorMsg(error.toString())
      }
    }
  }, [error])

  const buttonHandler = () => {
    if (userInfo) {
      dispatch(scanMarket(true))
    } else {
      setErrorMsg("Please Login To View Market Scan View")
    }
  }

  return (
    <>
      <Meta title="ProjectAlpha | MarketScan" description="Market Scan Page" keywords="ProjectAlpha, Finding Alphas, TA, Technical Analysis, Market Scan, Watchlist, Compare, Patterns" />
      <div style={{ pointerEvents: "none" }}>
        <IndexHeader className="mb-2" />
      </div>
      {loading ? (
        <Loader />
      ) : errorMsg ? (
        <Message variant="danger">{errorMsg}</Message>
      ) : (
        <>
          <Row>
            <Col>
              <h2 className="text-center">Market Scan</h2>
            </Col>
            <Col>
              <Button className="ml-1" style={{ backgroundColor: "#222", border: "none", color: "#e8b50f", height: "40px" }} onClick={() => buttonHandler()}>
                New Scan
              </Button>
            </Col>
          </Row>

          <Row className="ml-4">
            <Col>
              <Form>
                <Form.Check inline label="Show All" type="radio" defaultChecked name="radPattern" value={1} onClick={(e) => setPattern(1)} />
                <Form.Check inline label="Breakout Pattern" type="radio" name="radPattern" value={2} onClick={(e) => setPattern(2)} />
                <Form.Check inline label="Consolidating Pattern" type="radio" name="radPattern" value={3} onClick={(e) => setPattern(3)} />
                <Form.Check inline label="EMA Cross" type="radio" name="radPattern" value={4} onClick={(e) => setPattern(4)} />
                <Form.Check inline label="Trading Range" type="radio" name="radPattern" value={5} onClick={(e) => setPattern(5)} />
                <Form.Check inline label="Oversold (RSI)" type="radio" name="radPattern" value={6} onClick={(e) => setPattern(6)} />
              </Form>
            </Col>
          </Row>
          <Row className="rowStyle redditStyle">
            <Col className="pl-4 " md="2">
              <strong className="pl-4 text-center"> Ticker</strong>
            </Col>
            <Col className="" md="10">
              <strong>Pattern</strong>
            </Col>
          </Row>
          {scanItems.length > 0 && pattern === 1 && scanItems.map((item) => <MarketScanComponent key={item.name} item={item} />)}
          {scanItems.length > 0 && pattern === 2 && scanItems.filter((item) => item.pattern.includes("Breaking")).map((item, index) => <MarketScanComponent key={item.name} item={item} />)}
          {scanItems.length > 0 && pattern === 3 && scanItems.filter((item) => item.pattern.includes("Consolidating")).map((item) => <MarketScanComponent key={item.name} item={item} />)}
          {scanItems.length > 0 && pattern === 4 && scanItems.filter((item) => item.pattern.includes("EMACross")).map((item) => <MarketScanComponent key={item.name} item={item} />)}
          {scanItems.length > 0 && pattern === 5 && scanItems.filter((item) => item.pattern.includes("TradingRange")).map((item) => <MarketScanComponent key={item.name} item={item} />)}
          {scanItems.length > 0 && pattern === 6 && scanItems.filter((item) => item.pattern.includes("Oversold")).map((item) => <MarketScanComponent key={item.name} item={item} />)}
        </>
      )}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  )
}

export default MarketScanPage
