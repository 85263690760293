import axios from "axios"
import { DELETE_WATCHLIST_FAIL, DELETE_WATCHLIST_REQUEST, DELETE_WATCHLIST_SUCCESS, GET_WATCHLIST_FAIL, GET_WATCHLIST_REQUEST, GET_WATCHLIST_SUCCESS, INSERT_WATCHLIST_FAIL, INSERT_WATCHLIST_REQUEST, INSERT_WATCHLIST_SUCCESS } from "../constants/watchlistConstants"

export const getWatchList = (id) => async (dispatch, getState) => {
  try {
    console.log("Frontend getWatchList User Id: " + id)
    dispatch({
      type: GET_WATCHLIST_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    var baseUrl = process.env.REACT_APP_BACKEND_URL
    const { data } = await axios.get(`${baseUrl}/api/watchlist/${id}`, config)
    dispatch({
      type: GET_WATCHLIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_WATCHLIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const addWatchListItem = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: INSERT_WATCHLIST_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    var baseUrl = process.env.REACT_APP_BACKEND_URL
    const { data } = await axios.post(`${baseUrl}/api/watchlist`, params, config)
    dispatch({
      type: INSERT_WATCHLIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message = error.response && error.response.data.message ? error.response.data.message : error.message
    dispatch({
      type: INSERT_WATCHLIST_FAIL,
      payload: message,
    })
  }
}

export const deleteWatchListItem = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_WATCHLIST_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    var baseUrl = process.env.REACT_APP_BACKEND_URL
    const { data } = await axios.post(`${baseUrl}/api/watchlist/delete`, params, config)
    dispatch({
      type: DELETE_WATCHLIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message = error.response && error.response.data.message ? error.response.data.message : error.message
    dispatch({
      type: DELETE_WATCHLIST_FAIL,
      payload: message,
    })
  }
}
