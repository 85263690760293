import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { LinkContainer } from "react-router-bootstrap"
import { Navbar, Nav, Container, NavDropdown, Image } from "react-bootstrap"
import { logout } from "../actions/userActions"
import "../css/projectAlphaLink.css"

const Header = () => {
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const logoutHandler = () => {
    dispatch(logout())
  }

  return (
    <header className="gsFontHeader">
      <Navbar bg="#FFF" variant="light" expand="lg" collapseOnSelect>
        <>
          <Container>
            <LinkContainer to="/">
              <a href="/">
                <Image src="/images/projectalpha.png" width="270" height="80" />
              </a>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                {userInfo && (
                  <>
                    <NavDropdown className="linkStyle" title={userInfo.name} id="username">
                      <LinkContainer className="linkStyle ml-4" to="/profile">
                        <NavDropdown.Item>Profile</NavDropdown.Item>
                      </LinkContainer>
                      <NavDropdown.Item className="linkStyle ml-4" onClick={logoutHandler}>
                        Logout
                      </NavDropdown.Item>
                    </NavDropdown>
                  </>
                )}
                {!userInfo && (
                  <>
                    <NavDropdown title="Menu" id="mainmenu" style={{ width: 200 }}>
                      <LinkContainer to="/login">
                        <Nav.Link>Log in</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/register">
                        <Nav.Link>Register</Nav.Link>
                      </LinkContainer>
                      <NavDropdown.Divider />
                      <LinkContainer to="/aboutus">
                        <Nav.Link>About</Nav.Link>
                      </LinkContainer>
                    </NavDropdown>
                  </>
                )}
                {userInfo && userInfo.isAdmin && (
                  <NavDropdown title="Admin" id="adminmenu">
                    <LinkContainer to="/admin/userlist">
                      <NavDropdown.Item>Users</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                )}
              </Nav>
            </Navbar.Collapse>
            <LinkContainer className="linkStyle aContainer" to="/news">
              <Nav.Link>News</Nav.Link>
            </LinkContainer>
            <LinkContainer className="linkStyle aContainer" to="/compare">
              <Nav.Link>Compare</Nav.Link>
            </LinkContainer>
            <LinkContainer className="linkStyle aContainer" to="/scan">
              <Nav.Link>Scan</Nav.Link>
            </LinkContainer>
            <LinkContainer className="linkStyle aContainer" to="/watchlist">
              <Nav.Link>Watchlist</Nav.Link>
            </LinkContainer>
            <LinkContainer className="linkStyle aContainer" to="/reddit">
              <Nav.Link>Reddit</Nav.Link>
            </LinkContainer>
          </Container>
        </>
      </Navbar>
    </header>
  )
}

export default Header
