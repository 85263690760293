import React, { useEffect, useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import Loader from "../components/Loader"
import Message from "../components/Message"
import { getRedditPost } from "../actions/algorithmActions"
import IndexHeader from "../components/IndexHeader"
// import Autocomplete from "react-autocomplete"
// import { redditList } from "../constants/reddit"
import Meta from "../components/Meta"

const RedditPage = () => {
  const dispatch = useDispatch()
  const [forum, setForum] = useState("wallstreetbets")
  const [forumPosts, setForumPosts] = useState([])
  const [errorMsg, setErrorMsg] = useState("")
  const redditResult = useSelector((state) => state.redditResult)
  const { loading, error, posts } = redditResult

  useEffect(() => {
    if (forum) {
      dispatch(getRedditPost(forum))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    if (!loading) {
      if (posts) {
        if (posts.length > 0) {
          setForumPosts(posts)
        }
      }
    }
  }, [loading, posts])

  useEffect(() => {
    if (error) {
      if (error.toString() === "Not authorized, no token") {
        setErrorMsg("Please Login To View This Page")
      } else if (error.toString() === "Cannot read property 'token' of null") {
        setErrorMsg("Please Login To View This Page Or Reload This Page")
      } else if (error.toString() === "Request failed with status code 500") {
        setErrorMsg("Something Went Wrong... :(  Please Try To Reload Page!")
      } else {
        setErrorMsg(error.toString())
      }
    }
  }, [error])

  const forumHandler = (e) => {
    e.preventDefault()
    dispatch(getRedditPost(forum))
  }

  const forumHandler2 = (e) => {
    dispatch(getRedditPost(e))
  }

  return (
    <>
      <Meta title="ProjectAlpha | Reddit" description="Reddit Crawler" keywords="ProjectAlpha, Finding Alphas, TA, Technical Analysis, Market Scan, Watchlist, Compare, Patterns, Reddit" />
      <div style={{ pointerEvents: "none" }}>
        <IndexHeader className="mb-2" />
      </div>
      <Row>
        <Col>
          <Form onSubmit={forumHandler} style={{ display: "flex", width: "310px" }}>
            {/* <Form.Control type="text" placeholder="Enter Forum Name..." value={forum} onChange={(e) => setForum(e.target.value)}></Form.Control> */}
            {/* <Autocomplete
              className="ml-2"
              items={redditList}
              maxHeight={30}
              getItemValue={(item) => item.name}
              value={forum}
              shouldItemRender={(item, value) => item.name.toLowerCase().indexOf(forum.toLowerCase()) > -1}
              onChange={(e) => setForum(e.target.value)}
              onSelect={(e) => forumHandler2(e)}
              menuStyle={{
                borderRadius: "3px",
                boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                background: "rgba(255, 255, 255, 0.9)",
                padding: "2px 0",
                fontSize: "90%",
                position: "fixed",
                overflow: "auto",
                opacity: "1",
                maxHeight: "50%",
                color: "#ddd",
                zIndex: 100,
                // TODO: don't cheat, let it flow to the bottom
              }}
              renderItem={(item, highlighted) => (
                <div key={item.name} style={{ backgroundColor: highlighted ? "#eee" : "black", opacity: "1", color: highlighted ? "#333" : "#ddd" }}>
                  {item.name}
                </div>
              )}
            /> */}
            <Button type="submit" className="ml-1" variant="primary">
              <i className="fas fa-search"></i>
            </Button>
          </Form>
        </Col>
      </Row>
      {loading ? (
        <Loader />
      ) : errorMsg ? (
        <Message variant="danger">{errorMsg}</Message>
      ) : (
        <>
          <Row className="rowStyle redditStyle">
            <Col xs="7" sm="7" md="9">
              <strong>Thread Title</strong>
            </Col>
            <Col xs="2" sm="2" md="1">
              <strong>- # -</strong>
            </Col>
            <Col xs="3" sm="3" md="2">
              <strong>Score</strong>
            </Col>
          </Row>
          {forumPosts.length > 0 &&
            forumPosts.map((item, index) => (
              <Row className="rowStyle redditStyle" key={index}>
                <Col xs="7" sm="7" md="9">
                  <a href={item.url} className="redditStyle" target="_blank" rel="noreferrer">
                    {item.title.slice(0, 70)}
                  </a>
                </Col>
                <Col xs="2" sm="2" md="1">
                  {item.num_comments}
                </Col>
                <Col xs="3" sm="3" md="2">
                  {item.score}
                </Col>
              </Row>
            ))}
        </>
      )}
    </>
  )
}

export default RedditPage
