import React from "react"
import { Helmet } from "react-helmet"

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keyword" content={keywords} />
    </Helmet>
  )
}
//CC679FB1-BE29-4E0D-A715-53A1616B4239
Meta.defaultProps = {
  title: "ProjectAlpha",
  description: "We add security to your business",
  keywords: "Alpha Stocks ",
}

export default Meta
