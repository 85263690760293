import React, { useState, useEffect } from "react"
import { Form, Button, Row, Col } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { addWatchListItem } from "../actions/watchlistActions"

const AddWatchListItem = ({ onAdded }) => {
  const dispatch = useDispatch()
  const [ticker, setTicker] = useState("")
  const [loaded, setLoaded] = useState(false)
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const watchlistAddedItemResult = useSelector((state) => state.watchlistAddedItemResult)
  const { loading, success } = watchlistAddedItemResult

  const addWatchListItemHandler = (e) => {
    e.preventDefault()
    console.log("AddWatchListItemHandler: Dispatching AddWatchListItem")
    dispatch(addWatchListItem({ userid: userInfo._id, ticker: ticker }))
    setLoaded(true)
    setTicker("")
  }

  useEffect(() => {
    if (!loading) {
      if (success) {
        if (loaded) {
          console.log("Item Added", ticker)
          onAdded(true)
          setLoaded(false)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, success])

  return (
    <Row>
      <Col md="4">
        <Form onSubmit={addWatchListItemHandler} style={{ width: "300", display: "flex" }}>
          <Form.Control type="text" placeholder="[Enter ticker]" value={ticker} onChange={(e) => setTicker(e.target.value)}></Form.Control>
          {loaded}
          <Button type="submit" className="pl-2 pt-1" style={{ height: "31px", width: "33px", backgroundColor: "#222", color: "#e8b50f", border: "none" }}>
            <i className="fas fa-plus pb"></i>
          </Button>
        </Form>
      </Col>
      <Col>
        <h3 className="ml-4">Watchlist Page</h3>
      </Col>
    </Row>
  )
}

export default AddWatchListItem
