import React, { useEffect, useState } from "react"
import { Col, Row, Accordion, Card, Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import ReactCountryFlag from "react-country-flag"
import { getSymbolHistoryData } from "../actions/symbolActions"
import CandleChart from "../components/CandleChart"
import { addWatchListItem } from "../actions/watchlistActions"

const MarketScanComponent = ({ item }) => {
  const dispatch = useDispatch()
  const [chartData, setChartData] = useState([])
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const historyDataResult = useSelector((state) => state.historyDataResult)
  const { historyData } = historyDataResult

  useEffect(() => {
    if (historyData) {
      if (historyData.length > 0) {
        var data = historyData
        var dps1 = []
        console.log("Array Length: " + data.length)
        for (var i = 0; i < data.length; i++) {
          if (Number(data[i].Open) !== 0) {
            dps1.push({
              date: new Date(data[i].Date),
              open: Number(data[i].Open),
              high: Number(data[i].High),
              low: Number(data[i].Low),
              close: Number(data[i].Close),
              volume: Number(data[i].Volume),
              pattern: data[i].Pattern,
            })
          }
        }
        setChartData(dps1)
      }
    }
  }, [historyData])

  const onClickHandle = (ticker) => {
    setChartData([])
    dispatch(getSymbolHistoryData(ticker))
  }

  const addToWatchlist = (ticker) => {
    console.log("addToWatchlist")
    dispatch(addWatchListItem({ userid: userInfo._id, ticker: ticker }))
  }
  return (
    <Row key={item.name}>
      <Col md="12">
        <Accordion>
          <Card>
            <Accordion.Toggle onClick={(e) => onClickHandle(item.name)} className="panel-header rowStyle" as={Card.Header} eventKey={item.name}>
              {item.name.includes(".OL") && <ReactCountryFlag countryCode="NO" svg style={{ fontSize: "1.0em", lineHeight: "1.0em" }} />}
              <strong> {item.name}</strong>
              {item.pattern.replaceAll("CDL", "").replaceAll("_Bull", "")}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={item.name}>
              <>
                <Card.Body>
                  {chartData.length > 0 && (
                    <>
                      <CandleChart ticker={item.name.replaceAll(".OL", "")} sourceData={chartData} width={window.innerWidth * 0.69} />
                      <Button onClick={() => addToWatchlist(item.name)} style={{ backgroundColor: "#222", border: "none", color: "#e8b50f", boxShadow: "none" }}>
                        Add To Watchlist
                      </Button>
                    </>
                  )}
                </Card.Body>
              </>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Col>
    </Row>
  )
}

export default MarketScanComponent
