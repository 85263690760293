import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Message from "../components/Message"
import Loader from "../components/Loader"
import { getNewsfeed, getCompanyNewsContent } from "../actions/rssActions"
import { Button, ButtonGroup, Col, Row } from "react-bootstrap"

import { Modal } from "react-responsive-modal"
import "react-responsive-modal/styles.css"

const NewsComponent = () => {
  const dispatch = useDispatch()
  const [feed, setFeed] = useState([])
  const [newsHeader, setNewsHeader] = useState("")
  const [twitterVisible, setTwitterVisible] = useState(false)
  const [newsBody, setNewsBody] = useState("")
  const [newsDate, setNewsDate] = useState("")
  const [newsTicker, setNewsTicker] = useState("")
  const [newsCompany, setNewsCompany] = useState("")
  const [itemId, setItemId] = useState(0)
  const newsFeedResult = useSelector((state) => state.newsFeedResult)
  const { loadingNews, errorNews, newsFeed } = newsFeedResult

  const newsContentResult = useSelector((state) => state.newsContentResult)
  const { loadingContent, newsContent } = newsContentResult
  const [open, setOpen] = useState(false)
  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)

  const styles = {
    fontFamily: "sans-serif",
    textAlign: "center",
  }

  useEffect(() => {
    console.log("Fetcing News feed")
    dispatch(getNewsfeed())
  }, [dispatch])

  useEffect(() => {
    if (newsFeed) {
      if (newsFeed.data) {
        if (Array.isArray(newsFeed.data.messages)) {
          console.log("Setting News")
          newsFeed.data.messages = newsFeed.data.messages.filter((item) => item.title !== "Renteregulering")
          newsFeed.data.messages = newsFeed.data.messages.filter((item) => item.title !== "Ny rente")
          newsFeed.data.messages = newsFeed.data.messages.filter((item) => item.title !== "Rentefastsettelse")
          newsFeed.data.messages = newsFeed.data.messages.filter((item) => item.title !== "Financial calendar")
          setFeed(Array.from(newsFeed.data.messages))
        }
      }
    }
  }, [newsFeed])

  useEffect(() => {
    if (!loadingContent) {
      if (newsContent) {
        if (newsContent.data) {
          if (newsContent.data.message) {
            setNewsHeader(newsContent.data.message.title)
            setNewsBody(newsContent.data.message.body)
            setNewsDate(newsContent.data.message.publishedTime)
            setNewsCompany(newsContent.data.message.issuerName)
            setNewsTicker(newsContent.data.message.issuerSign)
            onOpenModal()
          }
        }
      }
    }
  }, [newsContent, loadingContent])

  const buttonHandler = (e) => {
    if (e) {
      setTwitterVisible(true)
    } else {
      setTwitterVisible(false)
      dispatch(getNewsfeed())
    }

    //  onOpenModal()
  }

  const onClose = () => {
    setNewsHeader("")
    setNewsBody("")
    setNewsDate("")
    setNewsCompany("")
    setNewsTicker("")
    newsContent.data.message = null
    setOpen(false)
    onCloseModal()
  }

  const newsContentHandler = (id) => {
    dispatch(getCompanyNewsContent(id))
    setItemId(id)
    //onOpenModal()
  }

  return (
    <>
      <Row style={{ backgroundColor: "#222", border: "none", color: "#e8b50f", borderRadius: "1mm" }}>
        <Col>
          <ButtonGroup aria-label="Basic example" style={{ backgroundColor: "#222", border: "none", color: "#e8b50f" }}>
            <Button className="mr-4" style={{ backgroundColor: "#222", border: "none", color: "#e8b50f" }} onClick={() => buttonHandler()}>
              Exchange News
            </Button>
            {"  "}
            <Button className="ml-4" style={{ backgroundColor: "#222", border: "none", color: "#e8b50f" }} onClick={() => buttonHandler("twitter")}>
              Newswire <i className="fab fa-twitter"></i>
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <Modal style={styles} open={open} onClose={onCloseModal}>
        <div className="newsStyle2 pt-2" style={{ fontSize: 14 }}>
          {new Date(newsDate).toLocaleString().slice(0, -3)}
          {" - "}
          {newsTicker}
          {" - "}
          {newsCompany}
          {" - Newsweb"}
        </div>
        <h2>{newsHeader}</h2>
        <p>{newsBody}</p>
        <a href={`https://newsweb.oslobors.no/message/${itemId}`} className="newsStyle" target="_blank" rel="noreferrer">
          https://newsweb.oslobors.no/message/{itemId}
        </a>
        <br /> <br />
        <Button onClick={() => onClose()} className="mt-4" style={{ backgroundColor: "#222", border: "none", color: "#e8b50f" }}>
          Close
        </Button>
      </Modal>
      {loadingNews ? (
        <Loader />
      ) : errorNews ? (
        <Message variant="danger">{errorNews}</Message>
      ) : (
        <>
          {!twitterVisible && (
            <Row style={{ overflowY: "scroll", height: 400 }}>
              {feed.slice(0, 75).map((item) => (
                <Col md="12" key={item.id}>
                  <div className="newsStyle2 pt-1">
                    {new Date(item.publishedTime).toLocaleString().slice(0, -3)}
                    {" - "}
                    {item.issuerSign}
                    {" - "}
                    {item.issuerName}
                    {" - Newsweb"}
                  </div>
                  <Button onClick={() => newsContentHandler(item.id)} style={{ textAlign: "left", outline: "none", backgroundColor: "transparent", borderColor: "transparent", boxShadow: "none" }}>
                    <strong className="newsStyle">{item.title}</strong>
                  </Button>
                </Col>
              ))}
            </Row>
          )}
        </>
      )}
    </>
  )
}

export default NewsComponent
