import React, { useState, useEffect } from "react"
import axios from "axios"
import * as am5 from "@amcharts/amcharts5"
import * as am5xy from "@amcharts/amcharts5/xy"
import { Root, Tooltip, color, Label, percent, Button, Graphics, Bullet } from "@amcharts/amcharts5"
import { ValueAxis, AxisRendererY, AxisRendererX, CandlestickSeries, XYCursor, XYChartScrollbar, LineSeries, ColumnSeries, GaplessDateAxis } from "@amcharts/amcharts5/xy"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"
import { StockChart, StockToolbar, StockPanel, StockLegend, IndicatorControl, ResetControl, SettingsControl, DrawingControl, ComparisonControl } from "@amcharts/amcharts5/stock"

function AmChartStockCompareComponent(props) {
  const [jsonData, setJsonData] = useState([])
  const [dateRange] = useState("5y")
  const [isClientDataReady, setIsClientDataReady] = useState(false)
  const [symbol, setSymbol] = useState("N/A")
  const [ticker, setTicker] = useState("")
  const [company, setCompany] = useState("")
  const [isReady, setIsReady] = useState(false)
  const [isCandle, setIsCandle] = useState(true)

  var stockChart = {}
  const today = new Date()
  const tmpDate = new Date(today)
  const futureDate = tmpDate.setDate(tmpDate.getDate() + 6)
  var valueSeries = {}

  const addMonths = (months) => {
    var date = new Date()
    date.setMonth(date.getMonth() + months)
    return date
  }

  const loadData = (tickerName = "") => {
    var url = "https://investor-websolutions.com/api/index/historical?" //YAHOO_BASE_URL
    var today = new Date()
    var startTimeStamp = new Date().setMonth(new Date().getMonth() - 60) // .getTime() / 1000)
    startTimeStamp = Math.round(startTimeStamp / 1000)
    var company = symbol
    if (tickerName !== "") {
      console.log("loadData: " + tickerName)
    }
    axios
      .get(`${url}`, {
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json;charset=UTF-8",
          symbol: tickerName !== "" ? tickerName : company,
          period1: startTimeStamp,
          period2: Math.round(today.getTime() / 1000),
          interval: "1d",
          events: "history",
          includeAdjustedClose: true,
        },
        params: {},
      })
      .then((result) => {
        if (!result.data) {
          console.log("Did Not Get Data (am_chart): ")
          return
        }
        // console.log("Loaded Data For widget am_chart: " + dateRange)
        var tmpJson = csvJSON(result.data)
        tmpJson = JSON.parse(tmpJson)
        for (var i = 0; i < tmpJson.length; i++) {
          tmpJson[i].date = new Date(tmpJson[i].date).getTime()
        }
        setJsonData(tmpJson)
        setIsReady(true)
      })
      .catch(function (error) {
        console.log("We got amChart Data Exception")
        console.log(error)
      })
  }

  useEffect(() => {
    if (props !== null) {
      if (props.symbol !== null) {
        console.log("Amchart: new prop symbol -> " + props.symbol)
        setTicker(props.symbol)
        setCompany(props.symbol)
        setSymbol(props.symbol)
        setIsClientDataReady(true)
      }
    }
  }, [props.symbol])

  useEffect(() => {
    if (isClientDataReady && symbol !== "N/A") {
      loadData(symbol)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClientDataReady, symbol])

  function csvJSON(csv) {
    var lines = csv.split("\n")
    var result = []
    lines[0] = lines[0].toLowerCase()
    var headers = lines[0].split(",")
    for (var i = 1; i < lines.length; i++) {
      var obj = {}
      var currentline = lines[i].split(",")
      for (var j = 0; j < headers.length; j++) {
        if (j === 0) {
          obj[headers[j]] = currentline[j]
        } else {
          obj[headers[j]] = Number(currentline[j])
        }
      }
      result.push(obj)
    }
    return JSON.stringify(result) //JSON
  }

  useEffect(() => {
    if (isReady) {
      let root = Root.new("chartdiv")
      root.setThemes([am5themes_Animated.new(root)])
      stockChart = root.container.children.push(StockChart.new(root, {}))

      root.numberFormatter.set("numberFormat", "#,###.00")

      var mainPanel = stockChart.panels.push(
        StockPanel.new(root, {
          wheelY: "zoomX",
          panX: true,
          panY: true,
          height: am5.percent(70),
        })
      )

      var watermark = Label.new(root, {
        text: company.toUpperCase(),
        fontSize: 120,
        fontWeight: "500",
        opacity: 0.15,
        textAlign: "center",
        x: percent(50),
        centerX: percent(50),
        y: percent(30),
        centerY: percent(30),
      })
      mainPanel.plotContainer.children.push(watermark)

      var valueAxis = mainPanel.yAxes.push(
        ValueAxis.new(root, {
          renderer: AxisRendererY.new(root, {
            pan: "zoom",
          }),
          tooltip: Tooltip.new(root, {}),
          numberFormat: "#,###.00",
          extraTooltipPrecision: 2,
        })
      )

      var dateAxis = mainPanel.xAxes.push(
        am5xy.GaplessDateAxis.new(root, {
          baseInterval: {
            timeUnit: "day",
            count: 1,
          },
          groupData: true,
          renderer: AxisRendererX.new(root, {}),
          tooltip: Tooltip.new(root, {}),
        })
      )
      if (isCandle) {
        valueSeries = mainPanel.series.push(
          CandlestickSeries.new(root, {
            name: company.toUpperCase(),
            calculateAggregates: true,
            groupData: true,
            clustered: false,
            valueXField: "date",
            valueYField: "close",
            highValueYField: "high",
            lowValueYField: "low",
            openValueYField: "open",
            calculateAggregates: true,
            xAxis: dateAxis,
            yAxis: valueAxis,
            stacked: true,
            zIndex: 1,
            //legendValueText: "{valueY}",
            tooltip: Tooltip.new(root, {}),
          })
        )

        valueSeries.get("tooltip").label.set("text", "[bold]{valueX.formatDate()}[/]\nOpen: {openValueY}\nHigh: {highValueY}\nLow: {lowValueY}\nClose: {valueY}")
        valueSeries.data.setAll(jsonData)
      } else {
        valueSeries = mainPanel.series.push(
          LineSeries.new(root, {
            name: company.toUpperCase(),
            xAxis: dateAxis,
            yAxis: valueAxis,
            groupData: true,
            openValueYField: "open",
            highValueYField: "high",
            lowValueYField: "low",
            valueYField: "close",
            valueXField: "date",
            tooltip: Tooltip.new(root, {}),
          })
        )

        valueSeries.get("tooltip").label.set("text", "[bold]{valueX.formatDate()}[/]\nClose: {valueY}")
        valueSeries.data.setAll(jsonData)

        valueSeries.events.once("datavalidated", function (ev, target) {
          dateAxis.zoomToDates(addMonths(-6), new Date())
        })
      }

      stockChart.set("stockSeries", valueSeries)

      var valueLegend = mainPanel.plotContainer.children.push(
        StockLegend.new(root, {
          stockChart: stockChart,
        })
      )
      valueLegend.data.setAll([valueSeries])

      mainPanel.set(
        "cursor",
        XYCursor.new(root, {
          yAxis: valueAxis,
          xAxis: dateAxis,
          snapToSeries: [valueSeries],
          snapToSeriesBy: "y!",
        })
      )

      var volumeAxisRenderer = AxisRendererY.new(root, {
        inside: false,
        zIndex: -100,
        stacked: true,
      })

      volumeAxisRenderer.labels.template.setAll({
        fill: am5.color(0x000000),
        y: percent(10),
      })
      var volumeAxis = mainPanel.yAxes.push(
        ValueAxis.new(root, {
          renderer: volumeAxisRenderer,
          height: 100,
          y: 495,
          centerY: percent(100),
          numberFormat: "#a",
        })
      )

      var firstColor = mainPanel.get("colors").getIndex(0)
      var volumeSeries = mainPanel.series.push(
        ColumnSeries.new(root, {
          name: company,
          clustered: false, //
          fill: firstColor,
          stroke: firstColor,
          valueYField: "volume",
          valueXField: "date",
          valueYGrouped: "sum",
          xAxis: dateAxis,
          yAxis: volumeAxis,
          groupData: true,
          legendValueText: "{valueY}",
          tooltip: Tooltip.new(root, {
            labelText: "{valueY}",
          }),
        })
      )
      volumeSeries.columns.template.setAll({
        strokeWidth: 0.2,
        strokeOpacity: 1,
        stroke: color(0xffffff),
      })
      //volumeSeries.yAxis.renderer.opposite = true
      volumeSeries.data.setAll(jsonData)

      // mainPanel.bottomAxesContainer.children.push(volumeSeries)
      // mainPanel.bottomAxesContainer.children.push(scrollbar)
      var scrollbar = mainPanel.set(
        "scrollbarX",
        XYChartScrollbar.new(root, {
          end: 0,
          orientation: "horizontal",
          height: 50,
        })
      )
      var sbDateAxis = scrollbar.chart.xAxes.push(
        GaplessDateAxis.new(root, {
          groupData: true,
          groupIntervals: [{ timeUnit: "day", count: 1 }],
          baseInterval: { timeUnit: "day", count: 1 },
          y: percent(100),
          centerY: percent(50),
          renderer: AxisRendererX.new(root, {
            opposite: true,
            strokeOpacity: 0,
          }),
        })
      )

      var sbValueAxis = scrollbar.chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      )

      var sbSeries = scrollbar.chart.series.push(
        am5xy.LineSeries.new(root, {
          valueYField: "close",
          valueXField: "date",
          xAxis: sbDateAxis,
          yAxis: sbValueAxis,
        })
      )

      sbSeries.fills.template.setAll({
        visible: true,
        fillOpacity: 0.3,
      })
      sbSeries.data.setAll(jsonData)
      var toolbar = StockToolbar.new(root, {
        container: document.getElementById("chartcontrols"),
        stockChart: stockChart,
        controls: [
          IndicatorControl.new(root, {
            stockChart: stockChart,
          }),
          ResetControl.new(root, {
            stockChart: stockChart,
          }),
          SettingsControl.new(root, {
            stockChart: stockChart,
          }),
          DrawingControl.new(root, {
            stockChart: stockChart,
          }),
          ComparisonControl.new(root, {
            stockChart: stockChart,
            searchable: true,

            items: [
              { label: "Apple", id: "AAPL" },
              { label: "Tesla", id: "TSLA" },
            ],
          }),
        ],
      })

      var m1 = mainPanel.plotContainer.children.push(
        Button.new(root, {
          dx: 150,
          dy: -15,
          width: 50,
          height: 30,
          layer: 20,
          color: color(0xdddddd),
          label: Label.new(root, {
            text: "1M",
            fontSize: 15,
            textAlign: "left",
            centerY: percent(25),
            centerX: 0,
            x: 0,
          }),
        })
      )

      m1.get("background").setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 0,
        cornerRadiusBR: 10,
        cornerRadiusBL: 0,
        fill: color(0x333333),
        fillOpacity: 0.7,
      })

      m1.events.on("click", function (ev) {
        dateAxis.zoomToDates(addMonths(-1), new Date(futureDate))
      })

      var m3 = mainPanel.plotContainer.children.push(
        Button.new(root, {
          dx: 200,
          dy: -15,
          width: 50,
          height: 30,
          layer: 20,
          color: color(0xdddddd),
          label: Label.new(root, {
            text: "3M",
            fontSize: 15,
            textAlign: "left",
            centerX: 0,
            centerY: percent(25),
            x: 0,
          }),
        })
      )

      m3.get("background").setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 0,
        cornerRadiusBR: 10,
        cornerRadiusBL: 0,
        fill: color(0x333333),
        fillOpacity: 0.7,
      })

      m3.events.on("click", function (ev) {
        dateAxis.zoomToDates(addMonths(-3), new Date(futureDate))
      })

      var m6 = mainPanel.plotContainer.children.push(
        Button.new(root, {
          dx: 250,
          dy: -15,
          width: 50,
          height: 30,
          layer: 20,
          color: color(0xdddddd),
          label: Label.new(root, {
            text: "6M",
            fontSize: 15,
            textAlign: "left",
            centerX: 0,
            centerY: percent(25),
            x: 0,
          }),
        })
      )

      m6.get("background").setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 0,
        cornerRadiusBR: 10,
        cornerRadiusBL: 0,
        fill: color(0x333333),
        fillOpacity: 0.7,
      })

      m6.events.on("click", function (ev) {
        dateAxis.zoomToDates(addMonths(-6), new Date(futureDate))
      })

      var y1 = mainPanel.plotContainer.children.push(
        Button.new(root, {
          dx: 300,
          dy: -15,
          width: 50,
          height: 30,
          layer: 20,
          color: color(0xdddddd),
          label: Label.new(root, {
            text: "1Y",
            fontSize: 15,
            textAlign: "left",
            centerX: 0,
            centerY: percent(25),
            x: 0,
          }),
        })
      )

      y1.get("background").setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 0,
        cornerRadiusBR: 10,
        cornerRadiusBL: 0,
        fill: color(0x333333),
        fillOpacity: 0.7,
      })

      y1.events.on("click", function (ev) {
        dateAxis.zoomToDates(addMonths(-12), new Date(futureDate))
      })

      var y3 = mainPanel.plotContainer.children.push(
        Button.new(root, {
          dx: 350,
          dy: -15,
          width: 50,
          height: 30,
          layer: 20,
          color: color(0xdddddd),
          label: Label.new(root, {
            text: "3Y",
            fontSize: 15,
            textAlign: "left",
            centerX: 0,
            centerY: percent(25),
            x: 0,
          }),
        })
      )

      y3.get("background").setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 0,
        cornerRadiusBR: 10,
        cornerRadiusBL: 0,
        fill: color(0x333333),
        fillOpacity: 0.7,
      })

      y3.events.on("click", function (ev) {
        dateAxis.zoomToDates(addMonths(-36), new Date(futureDate))
      })

      var button = mainPanel.plotContainer.children.push(
        Button.new(root, {
          dx: 100,
          dy: -15,
          width: 50,
          height: 30,
          layer: 20,
          color: color(0xdddddd),
          icon: Graphics.new(root, {
            fill: color(0xffffff),
            marginLeft: !isCandle ? "5px" : "",
            marginBottom: "5px",

            svgPath: !isCandle ? "M26,10H24V6H22v4H20V22h2v4h2V22h2ZM24,20H22V12h2Z" : "M1 15v-15h-1v16h16v-1h-15z,M9 8l-3-3-4 4v2l4-4 3 3 7-7v-2z",
          }),
        })
      )
      button.get("background").setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 0,
        cornerRadiusBR: 10,
        cornerRadiusBL: 0,
        fill: color(0x333333),
        fillOpacity: 0.7,
      })

      button.events.on("click", function (ev) {
        setIsCandle(!isCandle)
      })

      valueSeries.events.once("datavalidated", function (ev, target) {
        dateAxis.zoomToDates(addMonths(-6), new Date(futureDate))
      })

      return () => {
        root.dispose()
      }
    }
  }, [isReady, isCandle])

  return (
    <>
      <div id="chartcontrols"></div>
      <div id="chartdiv" style={{ width: "100%", height: "650px" }}></div>
    </>
  )
}

export default AmChartStockCompareComponent
