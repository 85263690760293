import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Form, Button, Row, Col } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import Message from "../components/Message"
import Loader from "../components/Loader"
import Meta from "../components/Meta"
import FormContainer from "../components/FormContainer"
import { login } from "../actions/userActions"
import { useNavigate } from "react-router-dom"

const LoginPage = ({ location, history }) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const redirect = "/"

  useEffect(() => {
    if (userInfo) {
      // Redirect to home page if user is already logged in
      navigate(redirect)
    }
  }, [userInfo, redirect])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(email, password))
  }

  return (
    <>
      <Meta title="WebSolutions Sign In" description="IR Widgets, Web Components" keywords="Webcomponents, Widgets, Charts, Quotes, Quote, Stock price, Income statements, Cash flow" />
      <FormContainer>
        <h1 className="mt-5">Sign in to your account</h1>
        {error && <Message variant="danger">{error}</Message>}
        {loading && <Loader />}
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="email">
            <Form.Label>E-mail</Form.Label>
            <Form.Control type="email" placeholder="Enter e-mail..." value={email} onChange={(e) => setEmail(e.target.value)}></Form.Control>
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Enter password..." value={password} onChange={(e) => setPassword(e.target.value)}></Form.Control>
          </Form.Group>
          <Button className="mb-5 standardBtn" type="submit">
            Log In
          </Button>
        </Form>
      </FormContainer>
    </>
  )
}

export default LoginPage
