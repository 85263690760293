import React, { useEffect, useState } from "react"
import { Row, Col, Container, Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import ReactCountryFlag from "react-country-flag"
import Message from "../components/Message"
import Loader from "../components/Loader"
import { getWatchList, deleteWatchListItem } from "../actions/watchlistActions"
import { calcSharpeRatio } from "../actions/algorithmActions"
import AddWatchListItem from "../components/AddWatchListItem"
import Meta from "../components/Meta"

const WatchListPage = ({ history }) => {
  const dispatch = useDispatch()
  const [sharpeData, setSharpeData] = useState([])
  const [errorMsg, setErrorMsg] = useState("")
  const [itemAdded, setItemAdded] = useState(false)
  const watchlistResult = useSelector((state) => state.watchlistResult)
  const { loading, error, watchlistData } = watchlistResult

  const deleteWatchlistResult = useSelector((state) => state.deleteWatchlistResult)
  const { success } = deleteWatchlistResult

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const sharpeRatioResult = useSelector((state) => state.sharpeRatioResult)
  const { isCalculating, sharpeRatio } = sharpeRatioResult

  useEffect(() => {
    if (userInfo) {
      console.log("WatchListPage: " + userInfo._id)
      dispatch(getWatchList(userInfo._id))
    } else {
      setErrorMsg("Please Login To See Watchlist Page...")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo])

  useEffect(() => {
    if (userInfo) {
      if (success) {
        console.log("Reload List After Delete")
        dispatch(getWatchList(userInfo._id))
      }
    } else {
      // history.push("/login")
      setErrorMsg("Please Login To See Watchlist Page...")
    }
  }, [dispatch, userInfo, success])

  useEffect(() => {
    if (!loading) {
      if (Array.isArray(watchlistData)) {
        var symbols = ""
        watchlistData.forEach((element) => {
          symbols = symbols + element.ticker + ","
        })
        if (symbols.length > 0) {
          dispatch(calcSharpeRatio(symbols.slice(0, -1)))
        }
      }
    }
  }, [dispatch, loading, watchlistData])

  useEffect(() => {
    if (!loading) {
      if (!isCalculating) {
        if (sharpeRatio) {
          var tmp = JSON.stringify(sharpeRatio).replace('"', "").replace('"', "")
          var sharpeArray = tmp.split("\\n")
          setSharpeData(sharpeArray)
        }
      }
    }
  }, [dispatch, loading, isCalculating, sharpeRatio])

  useEffect(() => {
    var i = 0
    if (userInfo) {
      if (itemAdded === true) {
        if (i === 0) {
          i = i + 1
          console.log("Reload List After Add", i)
          dispatch(getWatchList(userInfo._id))
          setItemAdded(false)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemAdded, userInfo])

  useEffect(() => {
    if (error) {
      if (error.toString() === "Not authorized, no token") {
        setErrorMsg("Please Login To View This Page")
      } else if (error.toString() === "Request failed with status code 500") {
        setErrorMsg("Something Went Wrong... :(  Please Try To Reload Page!")
      } else {
        setErrorMsg(error.toString())
      }
    }
  }, [error])

  const deleteItem = (item) => {
    console.log("Delete Item")
    dispatch(deleteWatchListItem({ id: userInfo._id, ticker: item.ticker }))
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{errorMsg}</Message>
      ) : (
        <>
          <Meta title="ProjectAlpha | Watchlist" description="Watchlist Page" keywords="ProjectAlpha, Finding Alphas, TA, Technical Analysis, Market Scan, Watchlist, Compare, Patterns" />
          <Container>
            <AddWatchListItem onAdded={setItemAdded} />
            <Row className="rowStyle">
              <Col xs="3" sm="3" md="2">
                <strong>Ticker</strong>
              </Col>
              <Col xs="2" sm="2" md="1">
                <strong>Today</strong>
              </Col>
              <Col xs="1" sm="1" md="1">
                <strong>Last</strong>
              </Col>
              <Col xs="1" sm="1" md="1">
                <strong>Initial</strong>
              </Col>
              <Col xs="1" sm="1" md="1">
                <strong>Change</strong>
              </Col>
              <Col xs="1" sm="1" md="1">
                <strong>Bid</strong>
              </Col>
              <Col xs="1" sm="1" md="1">
                <strong>Ask</strong>
              </Col>
              <Col xs="2" sm="2" md="1">
                <strong>Volume</strong>
              </Col>
            </Row>
          </Container>
          {watchlistData.map((watchlistItem, index) => (
            <Container key={index}>
              <Row className="rowStyle" style={{ height: "25px" }}>
                <Col xs="3" sm="3" md="2">
                  {watchlistItem.market === "no_market" && <ReactCountryFlag countryCode="NO" svg style={{ fontSize: "1.0em", lineHeight: "1.0em" }} />}
                  {watchlistItem.market === "us_market" && <ReactCountryFlag countryCode="US" svg style={{ fontSize: "1.0em", lineHeight: "1.0em" }} />}
                  {watchlistItem.region}
                  <strong> {watchlistItem.ticker}</strong>
                </Col>
                {watchlistItem.percent >= 0 && (
                  <Col style={{ color: "lightgreen" }} xs="2" sm="2" md="1">
                    <strong>{watchlistItem.percent}%</strong>
                  </Col>
                )}
                {watchlistItem.percent < 0 && (
                  <Col style={{ color: "red" }} xs="2" sm="2" md="1">
                    <strong>{watchlistItem.percent}%</strong>
                  </Col>
                )}
                <Col xs="1" sm="1" md="1">
                  {watchlistItem.last}
                </Col>
                <Col xs="1" sm="1" md="1">
                  {watchlistItem.initialValue}
                </Col>
                {watchlistItem.percentageChange >= 0 && (
                  <Col style={{ color: "lightgreen" }} xs="1" sm="1" md="1">
                    <strong>{watchlistItem.percentageChange}%</strong>
                  </Col>
                )}
                {watchlistItem.percentageChange < 0 && (
                  <Col style={{ color: "red" }} xs="1" sm="1" md="1">
                    <strong>{watchlistItem.percentageChange}%</strong>
                  </Col>
                )}
                <Col xs="1" sm="1" md="1">
                  {watchlistItem.bid}
                </Col>
                <Col xs="1" sm="1" md="1">
                  {watchlistItem.ask}
                </Col>
                <Col xs="2" sm="2" md="1" className="mb-2">
                  {watchlistItem.volume}
                </Col>
                <Button onClick={() => deleteItem(watchlistItem)} className="pt-0" style={{ height: "10", color: "black", outline: "none", backgroundColor: "transparent", borderColor: "transparent", boxShadow: "none" }}>
                  <i className="fa fa-trash"></i>
                </Button>
              </Row>
            </Container>
          ))}
          <br />
          <Col md="5">
            {!isCalculating &&
              sharpeData.map((item, index) => (
                <Container key={index}>
                  <Row className="rowStyleSharpe">
                    <Col className="pl-2">
                      <strong> {item}</strong>
                    </Col>
                  </Row>
                </Container>
              ))}
          </Col>
        </>
      )}
    </>
  )
}

export default WatchListPage
