import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Form, Button, Row, Col } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import Message from "../components/Message"
import Loader from "../components/Loader"
import FormContainer from "../components/FormContainer"
import { register } from "../actions/userActions"
import Meta from "../components/Meta"
import { useNavigate } from "react-router-dom"

const RegisterPage = ({ match, location, history }) => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [message, setMessage] = useState(null)

  const dispatch = useDispatch()

  const userRegister = useSelector((state) => state.userRegister)
  const { loading, error, userInfo } = userRegister
  const navigate = useNavigate()
  const redirect = "/"

  useEffect(() => {
    if (userInfo) {
      navigate(redirect)
    }
  }, [userInfo, redirect])

  const submitHandler = (e) => {
    e.preventDefault()
    if (password.length < 10) {
      setMessage("Passwords needs to be longer than 10 characters")
    } else {
      dispatch(register(name, email, password))
    }
  }

  return (
    <>
      <Meta title="WebSolutions Register" description="IR Widgets, Web Components" keywords="Webcomponents, Widgets, Charts, Quotes, Quote, Stock price, Income statements, Cash flow" />
      <FormContainer>
        <div className="mt-4 mb-4" style={{ fontSize: 33, fontFamily: "Raleway" }}>
          Start My Free Trial
        </div>

        {message && <Message variant="danger">{message}</Message>}
        {error && <Message variant="danger">{error}</Message>}
        {loading && <Loader />}
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control required type="name" placeholder="Enter name" value={name} onChange={(e) => setName(e.target.value)}></Form.Control>
          </Form.Group>

          <Form.Group controlId="email">
            <Form.Label>Email Address</Form.Label>
            <Form.Control required type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)}></Form.Control>
          </Form.Group>

          <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Enter password" value={password} onChange={(e) => setPassword(e.target.value)}></Form.Control>
          </Form.Group>

          <Form.Group controlId="confirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control required type="password" placeholder="Confirm password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}></Form.Control>
          </Form.Group>
          <Form.Group controlId="termscondition">
            <Form.Check required label="I Agree to Terms and Conditions" feedback="You must agree before submitting." />
          </Form.Group>

          <Button type="submit" variant="primary">
            Create My Free Account
          </Button>
        </Form>
        <Row className="py-3">
          <Col>
            Have an Account? <Link to={redirect ? `/signin?redirect=${redirect}` : "/signin"}>Sign In</Link>
          </Col>
        </Row>
      </FormContainer>
    </>
  )
}

export default RegisterPage
