export const GET_SYMBOL_REQUEST = "GET_SYMBOL_REQUEST"
export const GET_SYMBOL_SUCCESS = "GET_SYMBOL_SUCCESS"
export const GET_SYMBOL_FAIL = "GET_SYMBOL_FAIL"

export const GET_HISTORY_REQUEST = "GET_HISTORY_REQUEST"
export const GET_HISTORY_SUCCESS = "GET_HISTORY_SUCCESS"
export const GET_HISTORY_FAIL = "GET_HISTORY_FAIL"

export const GET_TICKERLIST_REQUEST = "GET_TICKERLIST_REQUEST"
export const GET_TICKERLIST_SUCCESS = "GET_TICKERLIST_SUCCESS"
export const GET_TICKERLIST_FAIL = "GET_TICKERLIST_FAIL"
