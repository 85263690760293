import { GET_COMPANY_NEWS_FAIL, GET_COMPANY_NEWS_REQUEST, GET_COMPANY_NEWS_SUCCESS, GET_CONTENT_NEWS_FAIL, GET_CONTENT_NEWS_REQUEST, GET_CONTENT_NEWS_SUCCESS, GET_NEWS_FAIL, GET_NEWS_REQUEST, GET_NEWS_SUCCESS, GET_RSS_FAIL, GET_RSS_REQUEST, GET_RSS_SUCCESS } from "../constants/rssConstants"

export const getRSSfeedReducer = (state = { RSSfeed: {} }, action) => {
  switch (action.type) {
    case GET_RSS_REQUEST:
      return { loadingFeed: true, RSSfeed: {} }
    case GET_RSS_SUCCESS:
      return {
        loadingFeed: false,
        RSSfeed: action.payload,
      }
    case GET_RSS_FAIL:
      return { loadingFeed: false, errorFeed: action.payload }
    default:
      return state
  }
}

export const getNewsfeedReducer = (state = { newsFeed: {} }, action) => {
  switch (action.type) {
    case GET_NEWS_REQUEST:
      return { loadingNews: true, newsFeed: {} }
    case GET_NEWS_SUCCESS:
      return {
        loadingNews: false,
        newsFeed: action.payload,
      }
    case GET_NEWS_FAIL:
      return { loadingNews: false, errorNews: action.payload }
    default:
      return state
  }
}

export const getCompanyNewsReducer = (state = { companyNews: {} }, action) => {
  switch (action.type) {
    case GET_COMPANY_NEWS_REQUEST:
      return { loadingNewsCompany: true, companyNews: {} }
    case GET_COMPANY_NEWS_SUCCESS:
      return {
        loadingNewsCompany: false,
        companyNews: action.payload,
      }
    case GET_COMPANY_NEWS_FAIL:
      return { loadingNewsCompany: false, errorCompanyNews: action.payload }
    default:
      return state
  }
}

export const getCompanyNewsContentReducer = (state = { newsContent: {} }, action) => {
  switch (action.type) {
    case GET_CONTENT_NEWS_REQUEST:
      return { loadingContent: true, newsContent: {} }
    case GET_CONTENT_NEWS_SUCCESS:
      return {
        loadingContent: false,
        newsContent: action.payload,
      }
    case GET_CONTENT_NEWS_FAIL:
      return { loadingContent: false, errorContent: action.payload }
    default:
      return state
  }
}
