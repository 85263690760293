import axios from "axios"
import { GET_INDEX_FAIL, GET_INDEX_REQUEST, GET_INDEX_SUCCESS } from "../constants/indexConstants"

export const getIndexData = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_INDEX_REQUEST,
    })

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    }
    var baseUrl = process.env.REACT_APP_BACKEND_URL
    console.log(`${baseUrl}/api/index`)
    const { data } = await axios.get(`${baseUrl}/api/index`, config)
    dispatch({
      type: GET_INDEX_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_INDEX_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
