import React, { useState } from "react"
import { format } from "d3-format"
import { ChartCanvas, Chart } from "react-stockcharts"
import { XAxis, YAxis } from "react-stockcharts/lib/axes"
import { CandlestickSeries, LineSeries, MACDSeries } from "react-stockcharts/lib/series"
import { last } from "react-stockcharts/lib/utils"
import { ema, macd, change } from "react-stockcharts/lib/indicator"
import algo from "react-stockcharts/lib/algorithm"
import { timeFormat } from "d3-time-format"
import { OHLCTooltip, MovingAverageTooltip, MACDTooltip } from "react-stockcharts/lib/tooltip"
import { CrossHairCursor, EdgeIndicator, CurrentCoordinate, MouseCoordinateY, MouseCoordinateX } from "react-stockcharts/lib/coordinates"
import { Label, Annotate, SvgPathAnnotation, buyPath, sellPath } from "react-stockcharts/lib/annotation"
import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale"

const CandleChart = ({ ticker, sourceData, width }) => {
  const [symbol] = useState(ticker)

  const macdAppearance = {
    stroke: {
      macd: "#FF0000",
      signal: "#00F300",
    },
    fill: {
      divergence: "#4682B4",
    },
  }

  const mouseEdgeAppearance = {
    textFill: "#542605",
    stroke: "#05233B",
    strokeOpacity: 1,
    strokeWidth: 3,
    arrowWidth: 5,
    fill: "#BCDEFA",
  }

  const initialData = sourceData

  const ema20 = ema()
    .options({
      windowSize: 8,
      sourcePath: "close",
    })
    .skipUndefined(true)
    .merge((d, c) => {
      d.ema20 = c
    })
    .accessor((d) => d.ema20)
    .stroke("blue")

  const ema50 = ema()
    .options({ windowSize: 16 })
    .merge((d, c) => {
      d.ema50 = c
    })
    .accessor((d) => d.ema50)

  const macdCalculator = macd()
    .options({
      fast: 12,
      slow: 26,
      signal: 9,
    })
    .merge((d, c) => {
      d.macd = c
    })
    .accessor((d) => d.macd)

  const changeCalculator = change()

  const buySell = algo()
    .windowSize(2)
    .accumulator(([prev, now]) => {
      const { ema20: prevShortTerm, ema50: prevLongTerm } = prev
      const { ema20: nowShortTerm, ema50: nowLongTerm } = now
      if (prevShortTerm < prevLongTerm && nowShortTerm > nowLongTerm) return "LONG"
      if (prevShortTerm > prevLongTerm && nowShortTerm < nowLongTerm) return "SHORT"
    })
    .merge((d, c) => {
      d.longShort = c
    })

  const defaultAnnotationProps = {
    onClick: console.log.bind(console),
  }

  const longAnnotationProps = {
    ...defaultAnnotationProps,
    y: ({ yScale, datum }) => yScale(datum.low),
    fill: "#006517",
    path: buyPath,
    tooltip: "MA - Go Long",
  }

  const bullAnnotationProps = {
    ...defaultAnnotationProps,
    y: ({ yScale, datum }) => yScale(datum.low),
    fill: "#006517",
    path: buyPath,
    tooltip: (d) => d.pattern.replace("CDL", "").replace("_Bull", ""),
  }

  const bearAnnotationProps = {
    ...defaultAnnotationProps,
    y: ({ yScale, datum }) => yScale(datum.high),
    fill: "#FF0000",
    path: sellPath,
    tooltip: (d) => d.pattern.replace("CDL", "").replace("_Bear", ""),
  }

  const shortAnnotationProps = {
    ...defaultAnnotationProps,
    y: ({ yScale, datum }) => yScale(datum.high),
    fill: "#FF0000",
    path: sellPath,
    tooltip: "MA - Go Short",
  }
  const margin = { left: 80, right: 80, top: 30, bottom: 50 }
  const calculatedData = buySell(ema20(ema50(macdCalculator(changeCalculator(initialData)))))
  const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor((d) => d.date)
  const { data, xScale, xAccessor, displayXAccessor } = xScaleProvider(calculatedData)
  const start = xAccessor(last(data))
  const end = xAccessor(data[Math.max(0, data.length - 150)])
  const xExtents = [start, end]

  return (
    <div>
      <ChartCanvas ratio={width / 600} width={width} height={565} margin={{ left: 50, right: 50, top: 10, bottom: 30 }} seriesName="MSFT" data={data} type="svg" xScale={xScale} xAccessor={xAccessor} displayXAccessor={displayXAccessor} xExtents={xExtents}>
        <Label x={(width - margin.left - margin.right) / 2} y={250} fontSize={120} opacity={0.1} text={symbol} />
        <Chart id={0} yExtents={(d) => [d.high, d.low]} height={width / 2.6}>
          <XAxis axisAt="bottom" orient="bottom" outerTickSize={0} />
          <YAxis axisAt="right" orient="right" ticks={5} />
          <CandlestickSeries />
          <EdgeIndicator itemType="last" orient="right" edgeAt="right" yAccessor={(d) => d.close} fill={(d) => (d.close > d.open ? "#A2F5BF" : "#F9ACAA")} stroke={(d) => (d.close > d.open ? "#0B4228" : "#6A1B19")} textFill={(d) => (d.close > d.open ? "#0B4228" : "#420806")} strokeOpacity={1} strokeWidth={3} arrowWidth={2} />
          <OHLCTooltip fontSize={16} origin={[-40, 0]} />
          <Annotate with={SvgPathAnnotation} when={(d) => d.pattern.includes("Bull")} usingProps={bullAnnotationProps} />
          <Annotate with={SvgPathAnnotation} when={(d) => d.pattern.includes("Bear")} usingProps={bearAnnotationProps} />
          <LineSeries yAccessor={ema20.accessor()} stroke={ema20.stroke()} />
          <LineSeries yAccessor={ema50.accessor()} stroke={ema50.stroke()} />
          <CurrentCoordinate yAccessor={ema20.accessor()} fill={ema20.stroke()} />
          <CurrentCoordinate yAccessor={ema50.accessor()} fill={ema50.stroke()} />
          <MovingAverageTooltip
            onClick={(e) => console.log(e)}
            origin={[-38, 15]}
            options={[
              {
                yAccessor: ema20.accessor(),
                type: "EMA",
                stroke: ema20.stroke(),
                windowSize: ema20.options().windowSize,
              },
              {
                yAccessor: ema50.accessor(),
                type: "EMA",
                stroke: ema50.stroke(),
                windowSize: ema50.options().windowSize,
              },
            ]}
          />
          <Annotate with={SvgPathAnnotation} when={(d) => d.longShort === "LONG"} usingProps={longAnnotationProps} />
          <Annotate with={SvgPathAnnotation} when={(d) => d.longShort === "SHORT"} usingProps={shortAnnotationProps} />
          <CurrentCoordinate yAccessor={ema20.accessor()} fill={ema20.stroke()} />
          <MouseCoordinateX at="bottom" orient="bottom" displayFormat={timeFormat("%Y-%m-%d")} rectRadius={5} {...mouseEdgeAppearance} />
          <MouseCoordinateY at="right" orient="right" displayFormat={format(".2f")} {...mouseEdgeAppearance} />
        </Chart>
        <Chart id={3} height={130} yExtents={macdCalculator.accessor()} origin={(w, h) => [0, h - 99]} padding={{ top: 10, bottom: 10 }}>
          <YAxis axisAt="right" orient="right" ticks={2} />
          <MouseCoordinateY at="right" orient="right" displayFormat={format(".2f")} {...mouseEdgeAppearance} />
          <MACDSeries yAccessor={(d) => d.macd} {...macdAppearance} />
          <MACDTooltip origin={[-38, 15]} yAccessor={(d) => d.macd} options={macdCalculator.options()} appearance={macdAppearance} />
        </Chart>
        <CrossHairCursor />
      </ChartCanvas>
    </div>
  )
}

export default CandleChart
