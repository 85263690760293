import axios from "axios"
import { csvParse } from "d3-dsv"
import { GET_HISTORY_FAIL, GET_HISTORY_REQUEST, GET_HISTORY_SUCCESS, GET_SYMBOL_FAIL, GET_SYMBOL_REQUEST, GET_SYMBOL_SUCCESS, GET_TICKERLIST_FAIL, GET_TICKERLIST_REQUEST, GET_TICKERLIST_SUCCESS } from "../constants/symbolConstants"

export const getSymbolData = (params) => async (dispatch) => {
  try {
    console.log("Frontend - getSymbolDatagetSymbolData: " + JSON.stringify(params))
    dispatch({
      type: GET_SYMBOL_REQUEST,
    })
    var baseUrl = process.env.REACT_APP_BACKEND_URL
    console.log(`${baseUrl}/api/symbol`)
    const config = {
      headers: {
        "Content-Type": "application/json",
        symbols: params,
      },
    }

    const { data } = await axios.get(`${baseUrl}/api/symbol`, config)
    dispatch({
      type: GET_SYMBOL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_SYMBOL_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getSymbolHistoryData = (ticker) => async (dispatch, getState) => {
  try {
    console.log("Frontend - getSymbolData: " + JSON.stringify(ticker))
    var baseUrl = process.env.REACT_APP_BACKEND_URL
    //console.log(`${baseUrl}/api/symbol`)
    dispatch({
      type: GET_HISTORY_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        symbols: ticker,
      },
    }

    const { data } = await axios.get(`${baseUrl}/api/symbol/history`, config)
    console.log(`${baseUrl}/api/symbol/history`)
    dispatch({
      type: GET_HISTORY_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_HISTORY_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getAllEuronextTickers = () => async (dispatch) => {
  try {
    console.log("Frontend - getAllEuronextTickers")
    dispatch({
      type: GET_TICKERLIST_REQUEST,
    })

    const { data } = await axios.get("/Euronext.csv")
    dispatch({
      type: GET_TICKERLIST_SUCCESS,
      payload: csvParse(data),
    })
  } catch (error) {
    dispatch({
      type: GET_TICKERLIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
