import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import ReactCountryFlag from "react-country-flag"
import HeatMapComponent from "../components/HeatMapComponent"
import Message from "../components/Message"
import Loader from "../components/Loader"
import { getSymbolData } from "../actions/symbolActions"
import { Button, Col, Row } from "react-bootstrap"

const USMarket = ({ onSelect }) => {
  const [market, setMarket] = useState("NO")
  const dispatch = useDispatch()
  const symbolDataResult = useSelector((state) => state.symbolDataResult)
  const { loading, error, symbolData } = symbolDataResult

  useEffect(() => {
    if (market === "US") {
      dispatch(getSymbolData("TSLA,MSFT,AAPL,GME,MSTR,NVDA,ARKK,HOOD,UBER,CSCO,NFLX,GOOGL"))
    } else if (market === "NO") {
      dispatch(getSymbolData("VAR.OL,NSKOG.OL,TEL.OL,AIRX.OL,HAFNI.OL,AKSO.OL,PGS.OL,SCATC.OL,RECSI.OL,AKRBP.OL,MOWI.OL,EQNR.OL,DNO.OL,TGS.OL"))
    } else if (market === "XX") {
      dispatch(getSymbolData("^TNX,^FVX,LBS=F,EURUSD=X,NOK=X,EURNOK=X,BTC-USD,GC=F,SI=F,ALI=F,QQQ,ES=F,NQ=F,YM=F,DOGE-USD,ETH-USD"))
    } else if (market === "UK") {
      dispatch(getSymbolData("RDSA.L,AZN.L,HSBA.L,GSK.L,BP.L,BNC.L,VOD.L,BARC.L,STAN.L,,RR.L,WIZZ.L,EZJ.L,CMCX.L,TM17.L,BATS.L"))
    }
  }, [dispatch, market])

  const buttonHandler = (e) => {
    e.preventDefault()
    console.log("preventDefault(2)")
    // onSelect(ticker)
  }

  return (
    <>
      <Row>
        <Col>
          <Button style={{ backgroundColor: "transparent", color: "#000", border: "0", boxShadow: "none" }} onClick={() => setMarket("NO")}>
            <ReactCountryFlag countryCode="NO" svg style={{ fontSize: "1.5em", lineHeight: "1.5em" }} />
          </Button>
          <Button style={{ backgroundColor: "transparent", color: "#000", border: "none", boxShadow: "none" }} onClick={() => setMarket("US")}>
            <ReactCountryFlag countryCode="US" svg style={{ fontSize: "1.5em", lineHeight: "1.5em" }} />
          </Button>
          <Button style={{ backgroundColor: "transparent", color: "#000", border: "none", boxShadow: "none" }} onClick={() => setMarket("UK")}>
            <ReactCountryFlag countryCode="GB" svg style={{ fontSize: "1.5em", lineHeight: "1.5em" }} />
          </Button>
          <Button style={{ backgroundColor: "transparent", color: "#000", border: "none", boxShadow: "none" }} onClick={() => setMarket("XX")}>
            <i className="fas fa-landmark"></i>
          </Button>
        </Col>
      </Row>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <HeatMapComponent data={symbolData} onSelect={onSelect} onClick={(e) => buttonHandler(e)} />
        </>
      )}
    </>
  )
}

export default USMarket
