import { GET_INDEX_FAIL, GET_INDEX_REQUEST, GET_INDEX_SUCCESS } from "../constants/indexConstants"

export const getIndexDataReducer = (state = { indexData: [] }, action) => {
  switch (action.type) {
    case GET_INDEX_REQUEST:
      return { ...state, loading: true, indexData: [] }
    case GET_INDEX_SUCCESS:
      return {
        loading: false,
        indexData: action.payload,
      }
    case GET_INDEX_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
