import { CALC_SHARPE_FAIL, CALC_SHARPE_REQUEST, CALC_SHARPE_SUCCESS, COMPARE_FAIL, COMPARE_REQUEST, COMPARE_SUCCESS, GET_REDDIT_FAIL, GET_REDDIT_REQUEST, GET_REDDIT_SUCCESS, SCAN_MARKET_FAIL, SCAN_MARKET_REQUEST, SCAN_MARKET_SUCCESS } from "../constants/algorithmConstants"

export const calcSharpeRatioReducer = (state = { sharpeRatio: {} }, action) => {
  switch (action.type) {
    case CALC_SHARPE_REQUEST:
      return { ...state, isCalculating: true, sharpeRatio: {} }
    case CALC_SHARPE_SUCCESS:
      return {
        isCalculating: false,
        sharpeRatio: action.payload,
      }
    case CALC_SHARPE_FAIL:
      return { isCalculating: false, error: action.payload }
    default:
      return state
  }
}

export const compareSymbolsReducer = (state = { historyCompareList: [] }, action) => {
  switch (action.type) {
    case COMPARE_REQUEST:
      return { ...state, compLoading: true, historyCompareList: [] }
    case COMPARE_SUCCESS:
      return {
        compLoading: false,
        historyCompareList: action.payload,
      }
    case COMPARE_FAIL:
      return { compLoading: false, compError: action.payload }
    default:
      return state
  }
}

export const getRedditPostReducer = (state = { posts: [] }, action) => {
  switch (action.type) {
    case GET_REDDIT_REQUEST:
      return { ...state, loading: true, posts: [] }
    case GET_REDDIT_SUCCESS:
      return {
        loading: false,
        posts: action.payload,
      }
    case GET_REDDIT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const scanMarketReducer = (state = { scanList: [] }, action) => {
  switch (action.type) {
    case SCAN_MARKET_REQUEST:
      return { ...state, loading: true, scanList: [] }
    case SCAN_MARKET_SUCCESS:
      return {
        loading: false,
        scanList: action.payload,
      }
    case SCAN_MARKET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
