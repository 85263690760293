import axios from "axios"
import { GET_COMPANY_NEWS_FAIL, GET_COMPANY_NEWS_REQUEST, GET_COMPANY_NEWS_SUCCESS, GET_CONTENT_NEWS_FAIL, GET_CONTENT_NEWS_REQUEST, GET_CONTENT_NEWS_SUCCESS, GET_NEWS_FAIL, GET_NEWS_REQUEST, GET_NEWS_SUCCESS, GET_RSS_FAIL, GET_RSS_REQUEST, GET_RSS_SUCCESS } from "../constants/rssConstants"

export const getRSSfeed = (feedUri) => async (dispatch) => {
  try {
    console.log("Frontend - getRSSfeed: " + feedUri)
    dispatch({
      type: GET_RSS_REQUEST,
    })

    const config = {
      headers: {
        "Content-Type": "application/json",
        feedUri: feedUri,
      },
    }
    var baseUrl = process.env.REACT_APP_BACKEND_URL
    const { data } = await axios.get(`${baseUrl}/api/rss`, config)
    dispatch({
      type: GET_RSS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_RSS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getNewsfeed = () => async (dispatch) => {
  try {
    console.log("Frontend - getNewsfeed ")
    dispatch({
      type: GET_NEWS_REQUEST,
    })

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    }
    var baseUrl = process.env.REACT_APP_BACKEND_URL
    const { data } = await axios.get(`${baseUrl}/api/rss/news`, config)
    dispatch({
      type: GET_NEWS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_NEWS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getCompanyNews = (ticker) => async (dispatch) => {
  try {
    console.log("Frontend - getCompanyNews " + ticker)
    dispatch({
      type: GET_COMPANY_NEWS_REQUEST,
    })

    const config = {
      headers: {
        "Content-Type": "application/json",
        ticker: ticker,
      },
    }

    var baseUrl = process.env.REACT_APP_BACKEND_URL
    const { data } = await axios.get(`${baseUrl}/api/rss/company`, config)
    dispatch({
      type: GET_COMPANY_NEWS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_COMPANY_NEWS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getCompanyNewsContent = (id) => async (dispatch) => {
  try {
    console.log("Frontend - getCompanyNewsContent: " + id)
    dispatch({
      type: GET_CONTENT_NEWS_REQUEST,
    })

    const config = {
      headers: {
        "Content-Type": "application/json",
        msgid: id,
      },
    }
    var baseUrl = process.env.REACT_APP_BACKEND_URL
    const { data } = await axios.get(`${baseUrl}/api/rss/content`, config)
    dispatch({
      type: GET_CONTENT_NEWS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_CONTENT_NEWS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
