import { createStore, combineReducers, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import { saveClientDataReducer, userGetClientDataReducer, sendNewPasswordReducer, userLoginReducer, userRegisterReducer, userDetailsReducer, userUpdateProfileReducer, userListReducer, userDeleteReducer, userUpdateReducer } from "./reducers/userReducers"
import { getIndexDataReducer } from "./reducers/indexReducers"
import { getSymbolDataReducer, getHistoryDataReducer, getAllEuronextTickersReducer } from "./reducers/symbolReducers"
import { getWatchListReducer, addWatchListItemReducer, deleteWatchListItemReducer } from "./reducers/watchlistReducer"
import { getRSSfeedReducer, getNewsfeedReducer, getCompanyNewsReducer, getCompanyNewsContentReducer } from "./reducers/rssReducers"
import { calcSharpeRatioReducer, compareSymbolsReducer, getRedditPostReducer, scanMarketReducer } from "./reducers/algorithmReducers"

const reducer = combineReducers({
  rssFeedResult: getRSSfeedReducer,
  newsFeedResult: getNewsfeedReducer,
  companyNewsResult: getCompanyNewsReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,

  newsContentResult: getCompanyNewsContentReducer,
  watchlistResult: getWatchListReducer,
  watchlistAddedItemResult: addWatchListItemReducer,
  deleteWatchlistResult: deleteWatchListItemReducer,
  indexDataResult: getIndexDataReducer,
  symbolDataResult: getSymbolDataReducer,
  historyDataResult: getHistoryDataReducer,
  getTickersResult: getAllEuronextTickersReducer,
  sharpeRatioResult: calcSharpeRatioReducer,
  compareResult: compareSymbolsReducer,
  redditResult: getRedditPostReducer,
  scanResult: scanMarketReducer,
  userLogin: userLoginReducer,
})

const userInfoFromStorage = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : null // {"_id":"6065e72e51f73536e4f8f4f1","name":"Aasmund Kvålsgard","email":"post@leanback.no","isAdmin":false,"token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYwNjVlNzJlNTFmNzM1MzZlNGY4ZjRmMSIsImlhdCI6MTY3Nzk2MTAwMywiZXhwIjoxNjgwNTUzMDAzfQ.xckOztH--bVblVFCZcxJlFarQ7jk9bQAG2AZobtQr9k"}
//localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : null

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store
