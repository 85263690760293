import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Row } from "react-bootstrap"
import { IndexComponent } from "./IndexComponent"
import { getIndexData } from "../actions/indexActions"

const IndexHeader = ({ onIndexSelect }) => {
  const dispatch = useDispatch()

  const indexDataResult = useSelector((state) => state.indexDataResult)
  const { loading, indexData } = indexDataResult
  const [data, setData] = useState(indexData)
  const [selectedTicker, setSelectedTicker] = useState("")

  useEffect(() => {
    dispatch(getIndexData())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedTicker.length > 0) {
      console.log("IndexHeader: onIndexSelect: ", selectedTicker)
      onIndexSelect(selectedTicker)
      setSelectedTicker("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTicker])

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getIndexData())
      if (indexData) {
        if (Array.isArray(indexData)) {
          if (indexData.length > 0) {
            setData(indexData)
          }
        }
      }
    }, 25000)
    return () => clearInterval(interval)
  }, [dispatch, indexData])

  useEffect(() => {
    if (!loading) {
      if (indexData) {
        if (Array.isArray(indexData)) {
          if (indexData.length > 0) {
            setData(indexData)
          }
        }
      }
    }
  }, [loading, indexData])

  return (
    <>
      <div style={{ padding: "0px 15px" }}>
        <Row className="center" style={{ background: "#222" }}>
          {data && data.map((ind, index) => <IndexComponent key={index} indexData={ind} onSelect={setSelectedTicker} />)}
        </Row>
      </div>
    </>
  )
}
export default IndexHeader
