import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"

const Footer = () => {
  return (
    <footer style={{ fontSize: 14 }}>
      <Container>
        <Row>
          <Col>
            <p className="text-center">Copyright &copy;2021 Leanback AS </p>
            <p className="text-center">Org.No 922077835</p>
            <p className="text-center">
              {" "}
              <Link className="text-center" to="/disclaimer">
                Disclaimer
              </Link>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
