import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import AboutUsPage from "./pages/AboutUsPage"
import WatchListPage from "./pages/WatchListPage"
import LoginPage from "./pages/LoginPage"
import RegisterPage from "./pages/RegisterPage"
import HomePage from "./pages/HomePage"
import Header from "./components/Header"
import Footer from "./components/Footer"
import NewsPage from "./pages/NewsPage"
import ComparePage from "./pages/ComparePage"
import RedditPage from "./pages/RedditPage"
import MarketScanPage from "./pages/MarketScanPage"
import DisclaimerPage from "./pages/DisclaimerPage"

const App = () => {
  return (
    <>
      <Router>
        <Header />

        <main className="mt-2 ml-1 mr-1">
          <div style={{ padding: "10px 20px", textAlign: "left" }}>
            <Routes>
              <Route path="/market" element={<HomePage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/aboutus" element={<AboutUsPage />} />
              <Route path="/reddit" element={<RedditPage />} />
              <Route path="/scan" element={<MarketScanPage />} />
              <Route path="/news" element={<NewsPage />} />
              <Route path="/compare" element={<ComparePage />} />
              <Route path="/watchlist" element={<WatchListPage />} />
              <Route path="/disclaimer" element={<DisclaimerPage />} />
              <Route path="/" element={<HomePage />} exact />
            </Routes>
          </div>
        </main>
        <Footer />
      </Router>
    </>
  )
}

export default App
