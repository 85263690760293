import { GET_HISTORY_FAIL, GET_HISTORY_REQUEST, GET_HISTORY_SUCCESS, GET_SYMBOL_FAIL, GET_SYMBOL_REQUEST, GET_SYMBOL_SUCCESS, GET_TICKERLIST_FAIL, GET_TICKERLIST_REQUEST, GET_TICKERLIST_SUCCESS } from "../constants/symbolConstants"

export const getSymbolDataReducer = (state = { symbolData: [] }, action) => {
  switch (action.type) {
    case GET_SYMBOL_REQUEST:
      return { ...state, loading: true, symbolData: [] }
    case GET_SYMBOL_SUCCESS:
      return {
        loading: false,
        symbolData: action.payload,
      }
    case GET_SYMBOL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getHistoryDataReducer = (state = { historyData: [] }, action) => {
  switch (action.type) {
    case GET_HISTORY_REQUEST:
      return { ...state, loading: true, historyData: [] }
    case GET_HISTORY_SUCCESS:
      return {
        loading: false,
        historyData: action.payload,
      }
    case GET_HISTORY_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getAllEuronextTickersReducer = (state = { tickerList: [] }, action) => {
  switch (action.type) {
    case GET_TICKERLIST_REQUEST:
      return { ...state, loading: true, tickerList: [] }
    case GET_TICKERLIST_SUCCESS:
      return {
        loading: false,
        tickerList: action.payload,
      }
    case GET_TICKERLIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
