export const CALC_SHARPE_REQUEST = "CALC_SHARPE_REQUEST"
export const CALC_SHARPE_SUCCESS = "CALC_SHARPE_SUCCESS"
export const CALC_SHARPE_FAIL = "CALC_SHARPE_FAIL"

export const COMPARE_REQUEST = "COMPARE_REQUEST"
export const COMPARE_SUCCESS = "COMPARE_SUCCESS"
export const COMPARE_FAIL = "COMPARE_FAIL"

export const GET_REDDIT_REQUEST = "GET_REDDIT_REQUEST"
export const GET_REDDIT_SUCCESS = "GET_REDDIT_SUCCESS"
export const GET_REDDIT_FAIL = "GET_REDDIT_FAIL"

export const SCAN_MARKET_REQUEST = "SCAN_MARKET_REQUEST"
export const SCAN_MARKET_SUCCESS = "SCAN_MARKET_SUCCESS"
export const SCAN_MARKET_FAIL = "SCAN_MARKET_FAIL"
